@import "@styles";

html {
  overflow-x: hidden;
}

body {
  font-family: Arial;
  -webkit-font-smoothing: antialiased;
  height: auto;
  width: calc(100% - 0px) !important;
}

strong {
  color: $titleColor;
}

// remove input background color for Chrome autocomplete
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

/* input */

.ant-form-item-label {
  padding: 0px !important;
}

.ant-form-item-label > label {
  font-weight: 500;
}

.ant-input-affix-wrapper {
  .ant-input-prefix {
    margin-right: 10px;
  }

  .ant-input-suffix {
    margin-left: 10px;
  }
}

.ant-input-number {
  width: 100%;
}

/* checkbox */

label.ant-checkbox-wrapper {
  align-items: center;

  .ant-checkbox {
    top: 0;

    .ant-checkbox-inner {
      border-radius: 6px;
    }
  }
}

.ant-tag {
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
  padding: 2px 8px;
}

.ant-table-column-sorter {
  padding: 0 8px;
}

.ant-form-item {
  margin-bottom: 12px;
}

.modal-actions {
  margin-top: 12px;
}

.ant-table-pagination {
  align-items: center;
}

.ant-divider-horizontal {
  margin: 8px 0px;
}

.action-buttons {
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 600px) {
    padding-top: 10px;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 0px;
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.15);
  background-color: gray;
}

.ant-space-vertical {
  width: 100%;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-right: 2px solid #4284f3;
}
.agencies-page,
.companies-page,
.policy-types-page,
.references-page,
.clients-page,
.policies-page,
.renewal-policies-page {
  .ant-table {
    display: block;

    @media screen and (max-width: 600px) {
      &-thead {
        display: none;
      }

      &-thead > tr,
      &-tbody > tr {
        th,
        td {
          &:first-of-type {
            padding-top: 1rem;
          }

          &:last-of-type {
            padding-bottom: 1rem;
          }
        }

        > th,
        > td {
          display: block;
          width: auto !important;
          border: none;
          padding: 0 1rem;
          font-size: 1.1rem;

          &:last-child {
            border-bottom: 1px solid #eee;
          }
        }
      }
    }
  }
}
