@import "@styles";

.drawer {
  @media (min-width: 1629px) {
    //#change
    display: none;
  }

  :global {
    .ant-drawer-body {
      padding: 0;

      &::-webkit-scrollbar {
        appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.06);
      }
    }
  }
}
