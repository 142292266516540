@import "@styles";

.licence-page {
  .licence-table-toolbar {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      margin-bottom: 0px;
    }

    .search-field {
      min-width: 324px;
      margin-right: 8px;
    }
    .date-selection-dropdown{
      min-width: 180px;
    }
  }

  .page-footer {
    width: 100%;
    margin-top: 16px;
    .footer-body {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
