.auto-complete {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .auto-complete-label {
    margin-bottom: 8px;
  }
}
.w-full {
  width: 100%;
}
