@import "@styles";

.policies-page {
  .policies-table-toolbar {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      margin-bottom: 0px;
    }

    .toolbar-right-item {
      display: flex;
      .search-field {
        min-width: 330px;
      }
      .date-selection-dropdown {
        min-width: 180px;
      }
    }
  }

  .policies-stats {
    width: 100%;
    padding: 12px 0;
    justify-content: space-between;
  }
}
