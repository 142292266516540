@import "@styles";

.sider {
  height: 99vh;
  :global(.ant-layout-sider-children) {
    display: flex;
    flex-direction: column;
  }
}

.sidebarDesktop {
  height: calc(100vh - 104px);
  background: white;
  padding-top: 80px;
  position: fixed;
  top: 64px;
  margin: 20px 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.logo {
  display: flex;
  justify-content: center;
  color: $primaryColor;
  // padding: 42px 0;

  a {
    display: flex;
  }
}

.menu {
  border-right: none;
  margin: 40px 0;

  :global {
    .ant-menu-item {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      // background-color: transparent !important;
      padding: 0 !important;
      margin-top: 0;
      margin-bottom: 12px !important;
      height: 50px;

      .ant-menu-item-icon {
        display: flex;
        align-items: center;
        // border-radius: 0 25px 25px 0;
        color: $placeholderColor;
        height: 100%;
        // padding-left: 22px;
        // padding-right: 14px;
        margin-right: 4px;
      }

      &:after {
        display: none;
      }

      &:hover {
        color: $titleColor;
        .ant-menu-item-icon {
          color: $bodyColor;
        }
      }

      &.ant-menu-item-selected {
        color: $titleColor;
        .ant-menu-item-icon {
          // background: change-color($primaryColor, $alpha: 0.15);
          background-color: $primaryColor;
          min-width: 8px;
        }
      }
    }
  }
}
