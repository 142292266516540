.left {
  display: flex;
  align-items: center;
  position: relative;
  background: url("../../../assets/images/auth.jpg");
  background-position: center, center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    display: none;
  }
}

.right {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 30px;
}
