.custom-modal {
  .modal-actions {
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .cancel-button {
      margin-right: 16px;
    }
  }
}
