.auto-complete {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .auto-complete-label {
    margin-bottom: 8px;
  }
  .date-field {
    width: 100%;
  }
}

.client-add-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
