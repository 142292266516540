.tableDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.pdf-table {
  width: 80%;
  border: 2px solid black;
  border-collapse: collapse;
  padding: 12px;
  display: flex;
  flex-direction: column;
}
/* th,
td {
  padding: 15px;
} */

.main {
  padding: 16px;
}
.title {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
}

.table-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.row {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 30%;
}

.w-30 {
  width: 20%;
}

.w-35 {
  width: 35%;
}

.address {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.vhnumDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 34px;
}

.address span {
  width: 70%;
  font-size: 18px;
  text-align: center;
}

.address span > span {
  width: 70%;
  font-size: 18px;
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

.ml-2 {
  margin-left: 12px;
}

.mb-2 {
  margin-left: 12px;
}
