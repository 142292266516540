/* Theme Color */
$primaryColor: #4285f4;

/* Status Color */
$infoColor: #3699ff;
$successColor: #2dcc89;
$warningColor: #ffb13c;
$errorColor: #ff5050;
/* Text Color */
$titleColor: #2d405a;
$bodyColor: #56606d;
$placeholderColor: #79828d;

//screen size

$max-width-sm: 600px !default;
