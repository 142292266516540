@import '@styles';

.form {
  width: 100%;
  max-width: 450px;
  margin: auto;

  &.disabled {
    pointer-events: none;
  }
}
