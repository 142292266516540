@import "@styles";

.section {
  width: 100%;
  min-height: calc(100vh - 104px);
  padding: 10px 0;
  background: #fff;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;

  @media (max-width: 768px) {
    padding: 20px;
  }

  & + .section {
    margin-top: 30px;
  }

  :global {
    .ant-card-head {
      padding: 0 30px;
    }

    .ant-card-head-title {
      color: $titleColor;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      padding: 27px 0;
    }

    .ant-card-body {
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    :global {
      .ant-card-head {
        padding: 0 15px;
      }
    }
  }
}

.footer {
  border-top: 1px solid #dee8f1;
  text-align: center;
  padding: 12px 30px;
}
