@import '@styles';

.card {
  border-radius: 4px;
  box-shadow: 0px 10px 36px rgba(0, 0, 0, 0.08);
  width: 260px;
}

.header {
  display: flex;
  align-items: center;
  background: $primaryColor;
  border-radius: 4px 4px 0 0;
  padding: 20px 30px;

  :global(.ant-avatar) {
    border: 1px solid #e7eff0;
  }

  > div {
    display: flex;
    flex-direction: column;
    margin-left: 12px;

    h4 {
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      margin: 0;
    }

    span {
      color: #fff;
      font-size: 11px;
      line-height: 18px;
    }
  }
}

.menu {
  background: #fff;
  border-radius: 0 0 4px 4px;
  padding: 10px 15px !important;

  :global {
    .ant-menu-item {
      font-weight: 500;
      border-radius: 4px;
      padding: 0 14px !important;
      margin: 5px 0 !important;
      height: 42px;

      &:after {
        display: none;
      }

      &:hover {
        color: $primaryColor;
      }

      &.ant-menu-item-selected {
        background: #f5f6fa;
        color: $primaryColor;
      }
    }
  }
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
