@import "@styles";

.rightPanel {
  width: 100%;
  display: flex;
  flex-direction: column !important;

  @media (max-width: 1629px) {
    //#change
    margin-left: 0;
  }
}

.content {
  width: calc(100vw - 64px);
  display: flex;
  padding: 20px 20px 0;
  margin-left: 64px;
  margin-right: auto;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;
    margin-left: 0px;
  }
}
