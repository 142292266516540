@import "@styles";

.references-page {
  .references-table-toolbar {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      margin-bottom: 0px;
    }
  }
}
