@import "@styles";

.header {
  min-height: 64px;
  height: unset;
  display: flex;
  align-items: center;
  padding: 10px 30px;
  color: #56606d;
  margin: 0;
  position: sticky;
  top: 0;
  background: #f5f6fa;
  z-index: 1000;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  background: white;
  border-bottom: 1px solid #dde1e5;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  @media (max-width: 768px) {
    padding-right: 15px;
  }

  &Shadow {
    box-shadow: #0e704a30 8px 0px 8px;
  }

  &Hidden {
    transform: translateY(-100%);
  }
}

.drawerToggle {
  font-size: 25px;
  padding: 15px;
  height: 55px;
  display: flex;
  align-items: center;

  @media (min-width: 769px) {
    display: none;
  }
}

.search {
  max-width: 400px;
  padding: 14px 20px;
  border-radius: 28px;
  border: none;

  svg {
    color: #99a6b9;
  }

  input {
    font-size: 14px;

    &::placeholder {
      color: #99a6b9;
    }
  }
}

.message,
.notification {
  color: #79828d;
  margin-left: 20px;
  cursor: pointer;

  :global(.ant-badge-count) {
    font-size: 9px;
    font-weight: 600;
    box-shadow: 0 0 0 2px #f5f6fa;
    background: #f64e60;
    top: 20%;
    right: 20%;
    padding: 0;
  }
}

.avatar {
  margin-left: 25px;
  cursor: pointer;

  :global(.ant-badge-dot) {
    box-shadow: 0 0 0 1px #f5f6fa;
    background: $primaryColor;
    top: 15%;
    right: 15%;
  }
}
.w-full {
  width: 100%;
}
.card {
  border-radius: 4px;
  box-shadow: 0px 10px 36px rgba(0, 0, 0, 0.08);
  min-width: 320px;
  background-color: #ffffff;
  padding: 12px;
}
.ml-8 {
  margin-left: 8px;
}
.filter-menu-actions {
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
