@import "@styles";

.renewal-policies-page {
  .renewal-policies-table-toolbar {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .title {
      margin-bottom: 0px;
    }
    .ant-space-item .datePicker-field {
      min-width: 230px;
    }

    .ant-space-item .search-field {
      min-width: 288px;
    }

    .toolbar-right-item {
      display: flex;
      align-items: center;

      .print-dropdown {
        margin-left: 8px;
      }
    }
  }
}
